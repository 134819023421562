import { loadableReady } from '@loadable/component';
import { enableDebug } from '@sitecore-jss/sitecore-jss-react';
import React from 'react';
import { createRoot, hydrateRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import AppRoot from './AppRoot';
import './assets/app.css';
import Logger from './lib/Logger';
import config from './temp/config';

if (process.env.REACT_APP_DEBUG) {
  enableDebug(process.env.REACT_APP_DEBUG);
}

let initLanguage = config.defaultLanguage;

/*
  SSR Data
  If we're running in a server-side rendering scenario,
  the server will provide JSON in the #__JSS_STATE__ element
  for us to acquire the initial state to run with on the client.

  This enables us to skip a network request to load up the layout data.
  We are emitting a quiescent script with JSON so that we can take advantage
  of JSON.parse()'s speed advantage over parsing full JS, and enable
  working without needing `unsafe-inline` in Content Security Policies.

  SSR is initiated from /server/server.js.
*/

let __JSS_STATE__ = null;
const ssrRawJson = document.getElementById('__JSS_STATE__');

if (ssrRawJson) {
  try {
    // textContent is preferable bc it will not try to parse content as HTML
    // https://developer.mozilla.org/en-US/docs/Web/API/Node/textContent#differences_from_innerhtml
    __JSS_STATE__ = JSON.parse(ssrRawJson.textContent);
  } catch (error) {
    Logger(error, { message: 'Error parsing __JSS_STATE__' });
  }
}

if (__JSS_STATE__) {
  // push the initial SSR state into the route handler, where it will be used
  // setServerSideRenderingState(__JSS_STATE__);
  // const rootElement = document.getElementById('root');

  // set i18n language SSR state language instead of static config default language
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  initLanguage = __JSS_STATE__.sitecore.context.language;
}

/*
  App Rendering
*/

// HTML element to place the app into
const rootElement = document.getElementById('root');

loadableReady(() => {
  if (__JSS_STATE__) {
    const root = createRoot(rootElement);
    root.render(
      <AppRoot path={window.location.pathname} Router={BrowserRouter} ssrState={__JSS_STATE__} />
    );
  } else {
    // when React initializes from a SSR-based initial state, you need to render with `hydrateRoot` instead of `render`
    hydrateRoot(
      rootElement,
      <AppRoot path={window.location.pathname} Router={BrowserRouter} ssrState={__JSS_STATE__} />
    );
  }
});
