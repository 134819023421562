import React, { forwardRef, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useBodyContext } from 'src/components/Common/ContentWrapper/context';
import { isInternal } from 'src/components/Common/Link';
import track from 'src/lib/Analytics';
import { pxPush } from 'src/lib/Analytics/px';
import { formatModalId } from 'src/lib/helpers/index';
import { OwnProps, Props, RichTextTag } from './types';

// Reference https://github.com/Sitecore/jss/blob/master/packages/sitecore-jss-react/src/components/RichText.tsx

const defaultElement = 'div';

/** @deprecated use MAM/_components/RichText instead */
const ForwardedRichText = forwardRef<React.Ref<Element>, OwnProps>(function RichText(
  {
    analytics,
    guid,
    field,
    className = '',
    tag: Tag = defaultElement,
    attributionCategory = '',
    ...props
  },
  ref
) {
  const history = useHistory();
  const { dispatch } = useBodyContext();

  const handleClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
    // If it's not an Element or an Anchor, do less 😤
    if (
      // Not an Element, return.
      !(event.target instanceof Element) ||
      // It is an Element, so is it (or its parent*) an anchor tag? If neither, return.
      // *We need to check the parentElement to catch scenarios like <a href="foo"><span>text</span></a>
      // where the event.target is clocked as a span.
      [
        event.target.tagName.toLowerCase() !== 'a',
        event.target.parentElement?.tagName.toLowerCase() !== 'a',
      ].every(Boolean)
    ) {
      return;
    }

    // We checked that either target or parent is an achor tag above
    // so now we can just get whichever one it is
    const anchorTag = event.target.closest('a');

    // Content-authors can trigger a modal from an <a> tag in the rich text html,
    // The tag should have data-toggle and data-target attributes
    const modalId = anchorTag?.attributes.getNamedItem('data-target')?.nodeValue;
    const isModalTrigger = anchorTag?.attributes.getNamedItem('data-toggle')?.nodeValue === 'modal';

    if (isModalTrigger && modalId) {
      event.preventDefault();
      dispatch({
        type: 'modalOpen',
        payload: { id: formatModalId(modalId) },
      });
    }

    const href = anchorTag?.attributes.getNamedItem('href')?.nodeValue;
    const getCategory = () => {
      return attributionCategory || 'rich-text';
    };

    const sendAnalytics = () => {
      analytics && analytics(event);
    };

    if (href) {
      sendAnalytics();

      track.component({
        category: getCategory(),
        action: [event.target.textContent, href].filter(Boolean).join(' | '),
        guid,
        label: html,
      });

      pxPush({ clickText: event.target.textContent as string, eventName: 'rich-text' });
    }

    // If it's an internal link, use react-router
    if (isInternal(href)) {
      event.preventDefault();
      history.push(href);
    }
  }, []);

  const normalizedValue = typeof field === 'string' ? { value: field } : field;

  const html = normalizedValue?.editable ? normalizedValue.editable : normalizedValue?.value;

  if (!html) return null;

  // Watch out for invalid html eg <RichText tag="p" field={{ value: '<div>hello</div>' }} />
  // This is NOT valid and in SSR when the server sends that invalid html the browser won't like it
  // and it will NOT reliably render.
  // However, since it is valid for div tags to hold any other tag, we can do a broad check for ANY wrapping element and if
  // true, swap out the specified tag with the default div. Not perfect, but it exists so that we can still write semantic
  // html, but not get fricked over if a content author sends rich text structure that conflicts with the tag defined in the app.
  const SafeTag = html?.charAt(0) === '<' ? defaultElement : Tag;
  return (
    <SafeTag
      data-testid="jss-rich-text"
      dangerouslySetInnerHTML={{ __html: html }}
      ref={ref}
      onClick={normalizedValue?.editable ? null : handleClick}
      {...{ ...props, className: `rich-text ${className}` }}
    />
  );
}) as <T extends RichTextTag = typeof defaultElement>(props: Props<T>) => JSX.Element;

export default ForwardedRichText;
